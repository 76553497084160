import { EmergencyRoomTypes } from '@dialogue/services'
import { notification } from 'antd'
import { all, call, put } from 'typed-redux-saga/macro'

import i18n from 'app/i18n'
import * as EpisodeViewActions from 'app/redux/episode-view/actions'
import { EpisodeViewTypes } from 'app/redux/episode-view/types'
import routes from 'app/services/routes'

import { initEmergencyRoomClient, takeKeyedLatest } from './utils'

export const POLLING_INTERVAL_MS = 2000

export function* updateEpisodeState({
  payload,
}: EpisodeViewActions.UpdateEpisodeStateAction) {
  const er = yield* call(initEmergencyRoomClient)
  const { episode_id } = payload
  try {
    if (payload.state_id === EmergencyRoomTypes.EpisodeState.RESOLVED) {
      yield* call<
        [string, EmergencyRoomTypes.EpisodeState.RESOLVED, string],
        typeof er.setEpisodeState
      >(
        er.setEpisodeState,
        episode_id,
        EmergencyRoomTypes.EpisodeState.RESOLVED,
        payload.outcome_id,
      )
    } else {
      // @ts-expect-error bad inference on union type
      yield* call(er.setEpisodeState, episode_id, payload.state_id)
    }
    yield* put(
      EpisodeViewActions.updateEpisodeStateSuccess(
        episode_id,
        payload.state_id,
      ),
    )

    yield* call(notification.info, {
      message: i18n.t('episodeView.stateUpdated', {
        state_id: payload.state_id,
      }),
    })

    if (payload.state_id === EmergencyRoomTypes.EpisodeState.RESOLVED) {
      window.location.hash = '#' + routes.dashboard()
    }
  } catch (error) {
    yield* put(EpisodeViewActions.updateEpisodeStateFailure(episode_id, error))
    yield* call(notification.error, {
      message: i18n.t('episodeView.stateUpdateError'),
      description: `${error.name} ${error.message}`,
    })
  }
}

export default function* episodeViewSaga() {
  yield* all([
    takeKeyedLatest(
      [
        EpisodeViewTypes.RESOLVE_EPISODE,
        EpisodeViewTypes.REOPEN_EPISODE,
        EpisodeViewTypes.SET_EPISODE_TO_PENDING,
      ],
      (action) => {
        return action.payload.episode_id
      },
      updateEpisodeState,
    ),
  ])
}

import { useMemo } from 'react'

import { CaretDownOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useTranslation } from 'react-i18next'

import { useViewerInstance } from 'app/containers/documents/document-details/viewer-context'
import { DocumentKind, type ViewerDocument } from 'app/redux/documents/viewer'

import { DOCUMENT_ACTION } from './types'

interface Props {
  document: ViewerDocument
  onActionClick?: (action: DOCUMENT_ACTION) => void
}

export const ModifyDocumentActions = ({ document, onActionClick }: Props) => {
  const { t } = useTranslation()

  const { loadedDocumentId } = useViewerInstance()

  const actionItems: ItemType[] = useMemo(() => {
    const items: ItemType[] = []

    if (document?.kind === DocumentKind.INCOMING_FAX_DOCUMENT) {
      items.push({
        key: 'split-document',
        label: t('documents.actions.splitDocument'),
        onClick: () => onActionClick?.(DOCUMENT_ACTION.SPLIT),
      })
    }

    return items
  }, [document?.kind, onActionClick, t])

  return (
    actionItems.length > 0 && (
      <Dropdown
        disabled={!loadedDocumentId}
        menu={{
          items: actionItems,
        }}
      >
        <Button
          size="small"
          type="primary"
          ghost
          data-testid="actions-dropdown"
        >
          {t('documents.actions.actionsDropdown')}
          <CaretDownOutlined />
        </Button>
      </Dropdown>
    )
  )
}

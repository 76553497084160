import type { NoteStatus, PageNote } from '@dialogue/notepad-client'
import { createApi } from '@reduxjs/toolkit/query/react'

import { getDynamicBaseUrl, API_NAMES } from './utils'

export enum Tags {
  PatientNotes = 'PatientNotes',
}

export const notepadApi = createApi({
  tagTypes: Object.values(Tags),
  reducerPath: 'notepadApi',
  baseQuery: getDynamicBaseUrl(API_NAMES.NOTEPAD),
  endpoints: (builder) => ({
    getPatientNotes: builder.query<
      PageNote,
      {
        patientId: number
        episodeId?: string
        title?: string
        status?: NoteStatus
        offset?: number
        limit?: number
      }
    >({
      query: ({ patientId, ...params }) => ({
        url: `/patients/${patientId}/notes/`,
        params,
      }),
      providesTags: (_result, _error, { patientId }) => [
        { type: Tags.PatientNotes, id: patientId },
      ],
    }),
  }),
})
export const { useGetPatientNotesQuery } = notepadApi

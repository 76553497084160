export enum TelephoneActionTypes {
  CALL_ENDED = '@@telephone/CALL_ENDED',
  UPDATE_CALL_STATUS = '@@telephone/UPDATE_CALL_STATUS',
  UPDATE_CALL_ERROR = '@@telephone/UPDATE_CALL_ERROR',
  MAKE_CALL = '@@telephone/MAKE_CALL',
  TOGGLE_MUTE = '@@telephone/TOGGLE_MUTE',
  HANG_UP = '@@telephone/HANG_UP',
  ADD_WARNING = '@@telephone/ADD_WARNING',
  REMOVE_WARNING = '@@telephone/REMOVE_WARNING',
  DTMF_INPUT = '@@telephone/DTMF_INPUT',
  HIDE_WINDOW = '@@telephone/HIDE_WINDOW',
  SHOW_WINDOW = '@@telephone/SHOW_WINDOW',
  SET_NUMBER = '@@telephone/SET_NUMBER',
  REDIAL = '@@telephone/REDIAL',
}

export type Status =
  | 'authenticated'
  | 'initialized'
  | 'dialing'
  | 'ringing'
  | 'connected'
  | 'connection error'
  | 'call ended'
  | 'error'

export interface TelephoneState {
  phoneNumber: string
  status: Status
  statusUpdatedAt: number
  error: Record<string, any>
  warnings: string[]
  windowVisible: boolean
  hungUp: boolean
  muted: boolean
}

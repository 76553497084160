import type { RuleObject } from 'antd/lib/form'

import type { SectionState } from './split-document-panel'

/**
 * A utility function for converting a string page range input into a numbers array.
 * Used for validating the split document logic.
 *
 * Accepted formats:
 * - Single page: `1`
 * - Page range: `1-3`
 * - Multiple pages: `1,2,3`
 */
export const convertStringPageRangeToNumbers = (value: string): number[] => {
  return value.split(',').flatMap((part) => {
    if (part.includes('-')) {
      const [start, end] = part.split('-').map(Number)
      return Array.from({ length: end - start + 1 }, (_, i) => start + i)
    }
    return [Number(part)]
  })
}

export const hasPageAlreadyBeenSelectedValidator = (
  sections: SectionState[],
  currentIndex: number,
) => {
  return (_: RuleObject, value: string): Promise<string> =>
    new Promise((resolve, reject) => {
      const numbers = convertStringPageRangeToNumbers(value)

      sections.some(
        (section, i) =>
          i !== currentIndex &&
          section.numbers.some((number) => numbers.includes(number)),
      )
        ? reject(new Error('Page already selected'))
        : resolve(value)
    })
}

import type { Task } from '@dialogue/coredata'
import styled from 'styled-components'

import { DateOffset } from 'app/components/date/date-offset'
import TaskOwner from 'app/components/tasks/task-owner'

interface TaskDetailsProps {
  task: Task
}

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
`

const TaskDetails = ({ task }: TaskDetailsProps) => {
  const { assignee_id, teams, scheduled_for } = task

  return (
    <DetailsContainer className="task-row-details">
      <TaskOwner assigneeId={assignee_id} teams={teams} />
      <DateOffset date={scheduled_for} />
    </DetailsContainer>
  )
}

export default TaskDetails

// @ts-strict-ignore
import { forwardRef, memo, useMemo } from 'react'

import type { Note } from '@dialogue/notepad-client'
import { Typography } from 'antd'
import type { RefSelectProps } from 'antd/lib/select'
import { useTranslation } from 'react-i18next'

import {
  ExtendedSelect,
  type ExtendedSelectProps,
  OptionContainer,
  OptionSubHeader,
} from 'app/components/extended-select'
import { formatDocumentDate } from 'app/containers/documents/helpers'
import { useTimeFromNow } from 'app/hooks'
import { normalizeString } from 'app/lib/helpers'
import { useGetPatientNotesQuery } from 'app/redux/api/notepadApi'
import { usePractitionerName } from 'app/redux/practitioners/hooks'

type Props = {
  memberId?: number
  noteId?: number
} & Partial<ExtendedSelectProps>

const NoteOptionLabel = memo(
  ({
    title,
    date,
    authorId,
  }: {
    title: string
    date: string
    authorId: number
  }) => {
    const { t } = useTranslation()
    const { name: authorName } = usePractitionerName(authorId)
    const timeFromAgo = useTimeFromNow(date)

    const formattedDate = formatDocumentDate({ t, date })

    return (
      <OptionContainer
        header={title}
        subHeader={
          <OptionSubHeader
            leftText={authorName}
            rightText={
              <span>
                {timeFromAgo} ({formattedDate})
              </span>
            }
          />
        }
      />
    )
  },
)

const handleNoteFilterOption = (input: string, option: Record<string, any>) => {
  const label = option?.label.props.title || ''
  return normalizeString(label).includes(normalizeString(input))
}

const ErrorMessage = () => {
  const { t } = useTranslation()
  return (
    <Typography.Text type="danger">
      {t('documents.errorLoadingNotesPicker')}
    </Typography.Text>
  )
}

export const NotePicker = forwardRef<RefSelectProps, Props>(
  ({ memberId, noteId, ...rest }, ref) => {
    const {
      data: { data: memberNotes } = { data: [] as Note[] },
      isFetching,
      isError,
    } = useGetPatientNotesQuery(
      {
        patientId: memberId,
      },
      { skip: !memberId },
    )

    const noteOptions = useMemo(() => {
      return memberNotes.map((note) => {
        return {
          label: (
            <NoteOptionLabel
              title={note.template.title[note.language]}
              date={note.completed_at || note.updated_at}
              authorId={note.created_by}
            />
          ),
          value: note.id,
        }
      })
    }, [memberNotes])

    return (
      <>
        <ExtendedSelect
          {...rest}
          ref={ref}
          loading={isFetching}
          showSearch
          allowClear
          filterOption={handleNoteFilterOption}
          options={noteOptions}
          key={noteId}
          defaultValue={noteId}
          dropdownMatchSelectWidth={false}
          status={isError && 'error'}
          optionLabelProp="label"
          data-testid="note-picker"
          data-private
        />
        {isError && <ErrorMessage />}
      </>
    )
  },
)

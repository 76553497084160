// @ts-strict-ignore
import { produce } from 'immer'
import type { ActionType } from 'typesafe-actions'

import type { TelephoneState } from 'app/redux/telephone/types'

import type * as actions from './actions'
import { TelephoneActionTypes } from './types'

export type TelephoneActions = ActionType<typeof actions>

export const INITIAL_STATE: TelephoneState = {
  error: null,
  phoneNumber: null,
  status: null,
  statusUpdatedAt: null,
  warnings: [],
  windowVisible: false,
  hungUp: false,
  muted: false,
}

export const reducer = (state = INITIAL_STATE, action: TelephoneActions) =>
  produce(state, (draft): typeof INITIAL_STATE | void => {
    switch (action.type) {
      case TelephoneActionTypes.UPDATE_CALL_STATUS:
        draft.status = action.payload
        draft.statusUpdatedAt = action.payload ? Date.now() : null
        break
      case TelephoneActionTypes.UPDATE_CALL_ERROR:
        draft.error = action.payload
        break
      case TelephoneActionTypes.ADD_WARNING:
        draft.warnings.unshift(action.payload)
        break
      case TelephoneActionTypes.REMOVE_WARNING:
        draft.warnings = draft.warnings.filter(
          (warning) => warning !== action.payload,
        )
        break
      case TelephoneActionTypes.SET_NUMBER:
        draft.phoneNumber = action.payload
        break
      case TelephoneActionTypes.SHOW_WINDOW:
        draft.windowVisible = true
        break
      case TelephoneActionTypes.HIDE_WINDOW:
        return INITIAL_STATE
      case TelephoneActionTypes.CALL_ENDED:
        draft.status = null
        draft.statusUpdatedAt = null
        draft.muted = false
        if (draft.windowVisible) draft.hungUp = true
        break
      case TelephoneActionTypes.REDIAL:
        draft.hungUp = false
        break
      case TelephoneActionTypes.TOGGLE_MUTE:
        draft.muted = !draft.muted
        break
    }
  })

export default reducer

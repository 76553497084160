import type { EmeraldTypes } from '@dialogue/services'
import type {
  ServerChannel,
  ChannelMembership,
} from '@mattermost/types/channels'
import type { UserProfile } from '@mattermost/types/users'
import { action } from 'typesafe-actions'

import {
  ChatTypes,
  type FullFileInfo,
  type Post,
  type PostList,
  type UserStatuses,
  type WSEvent,
  type WSResponse,
} from './types'

export const loadUserSuccess = (user: UserProfile) =>
  action(ChatTypes.LOAD_USER_SUCCESS, { user })

export const loadMembershipsSuccess = (
  memberships: Record<string, ChannelMembership>,
) => action(ChatTypes.LOAD_MEMBERSHIPS_SUCCESS, { memberships })

export const gotStatuses = (statuses: UserStatuses) =>
  action(ChatTypes.GOT_STATUSES, { statuses })

export const joinChannelSuccess = (membership: ChannelMembership) =>
  action(ChatTypes.JOIN_CHANNEL_SUCCESS, { membership })

export const leaveChannelSuccess = (channelId: string) =>
  action(ChatTypes.LEAVE_CHANNEL_SUCCESS, { channelId })

export const initChannel = (channelId: string) =>
  action(ChatTypes.INIT_CHANNEL, { channelId })

export const initChannelSuccess = (channelId: string) =>
  action(ChatTypes.INIT_CHANNEL_SUCCESS, { channelId })

export const initChannelFailure = (channelId: string, error: Error) =>
  action(ChatTypes.INIT_CHANNEL_FAILURE, { channelId, error })

export const loadPostsSuccess = (channelId: string, postsResponse: PostList) =>
  action(ChatTypes.LOAD_POSTS_SUCCESS, { channelId, postsResponse })

export const loadChannelSuccess = (
  channelId: string,
  channel: ServerChannel,
  members: UserProfile[],
  patientMmId: string,
  patientAppId: string,
  patientLastViewedAt: number,
) =>
  action(ChatTypes.LOAD_CHANNEL_SUCCESS, {
    channelId,
    channel,
    members,
    patientMmId,
    patientAppId,
    patientLastViewedAt,
  })

export const updatePatientLastViewedAt = (
  channelId: string,
  patientLastViewedAt: number,
) =>
  action(ChatTypes.UPDATE_PATIENT_LAST_VIEWED_AT, {
    channelId,
    patientLastViewedAt,
  })

export const addPost = (channelId: string, post: Post) =>
  action(ChatTypes.ADD_POST, { channelId, post })

export const clearChannel = (channelId: string) =>
  action(ChatTypes.CLEAR_CHANNEL, { channelId })

export const userTyping = (channelId: string, userId: string | null) =>
  action(ChatTypes.USER_TYPING, { channelId, userId })

export const updatePost = (channelId: string, post: Post) =>
  action(ChatTypes.UPDATE_POST, { channelId, post })

export const sendTextMessage = (
  channelId: string,
  message: string,
  options: {
    internal?: boolean
    filenames?: string[]
    file_ids?: string[]
    props?: Record<string, unknown>
  } = {},
) => action(ChatTypes.SEND_TEXT_MESSAGE, { channelId, message, options })

export const uploadIHFile = (
  patientId: number,
  channelId: string,
  file: EmeraldTypes.Document,
) => action(ChatTypes.UPLOAD_IH_FILE, { channelId, patientId, file })

export const sendCharge = (payload: {
  patientId: number
  channelId: string
  amount: number
  description: string
}) => action(ChatTypes.SEND_CHARGE, payload)

export const uploadFile = (channelId: string, file: File) =>
  action(ChatTypes.UPLOAD_FILE, { channelId, file })

export const uploadFileSuccess = (channelId: string, fileId: string) =>
  action(ChatTypes.UPLOAD_FILE_SUCCESS, { channelId, fileId })

export const uploadFileFailure = (channelId: string, fileError: Error) =>
  action(ChatTypes.UPLOAD_FILE_FAILURE, { channelId, fileError })

export const cancelUploadedFile = (channelId: string) =>
  action(ChatTypes.CANCEL_UPLOADED_FILE, { channelId })

export const receivedFileMetadata = (
  channelId: string,
  postId: string,
  fileMetadata: FullFileInfo,
) =>
  action(ChatTypes.RECEIVED_FILES_METADATA, {
    channelId,
    postId,
    fileMetadata,
  })

export const sendFile = (
  channelId: string,
  fileName: string,
  fileId: string,
  internal: boolean = false,
  additionalProps: Record<string, unknown> = {},
) =>
  action(ChatTypes.SEND_FILE, {
    channelId,
    fileName,
    fileId,
    internal,
    additionalProps,
  })

export const retryMessage = (messageId: string) =>
  action(ChatTypes.RETRY_MESSAGE, { messageId })

export const retractMessage = (payload: {
  channelId: string
  messageId: string
  patientId: string | number
}) => action(ChatTypes.RETRACT_MESSAGE, payload)

export const sendUserTyping = (channelId: string) =>
  action(ChatTypes.SEND_USER_TYPING, { channelId })

export const websocketEventReceived = (event: WSEvent) =>
  action(ChatTypes.WEBSOCKET_EVENT_RECEIVED, event)

export const websocketResponseReceived = (response: WSResponse) =>
  action(ChatTypes.WEBSOCKET_RESPONSE_RECEIVED, response)

export const clearAll = () => action(ChatTypes.CLEAR_ALL)

export const updateChannelViewed = (channelId: string) =>
  action(ChatTypes.UPDATE_CHANNEL_VIEWED, { channelId })

// @ts-strict-ignore
import {
  MemberApiFactory,
  type MemberApiCreateDocumentRequest,
} from '@dialogue/document-center'
import { notification } from 'antd'
import { all, call, put, select } from 'typed-redux-saga/macro'

import i18n from 'app/i18n'
import { Tags, documentCenterApi } from 'app/redux/api/document-center'
import { selectAccessToken } from 'app/redux/authentification/selectors'
import { chatActions, type FullFileInfo } from 'app/redux/chat'
import {
  memberDocumentsActions,
  type MemberAction,
  type MemberDocumentAction,
} from 'app/redux/documents/members'
import { documentViewerActions } from 'app/redux/documents/viewer'
import { uploadFile as uploadFileToMM } from 'app/sagas/chat'
import {
  initEmeraldV2Client,
  initMMClientV4,
  initUsherClient,
  takeKeyedLatest,
} from 'app/sagas/utils'

import { getClientConfig } from './utils'

export function* initMemberClient() {
  return MemberApiFactory(yield* call(getClientConfig))
}

export function* baseUploadMemberDocument({
  memberId,
  createdFromNoteId,
  ...rest
}: MemberApiCreateDocumentRequest) {
  const client = yield* call(initMemberClient)
  let response
  try {
    response = yield* call(client.createDocument, {
      memberId,
      // An issue with doccenter autogenerated types results in null not being an accepted value
      createdFromNoteId: createdFromNoteId ?? undefined,
      ...rest,
    })
  } catch (error) {
    if (error.response?.status !== 409) throw error

    // 409 means that the document was already saved.
    // The response contains the existing document data.
    response = error.response
  }

  yield* call(
    getMemberDocumentFilters,
    memberDocumentsActions.getDocumentFilters({ memberId }),
  )

  return response.data.data
}

export function* uploadMemberDocument({
  memberId,
  document,
  ...rest
}: MemberApiCreateDocumentRequest) {
  try {
    const uploadedDocument = yield* call(baseUploadMemberDocument, {
      memberId,
      document,
      ...rest,
    })

    yield* put(
      memberDocumentsActions.savedDocument({
        memberId,
        document: uploadedDocument,
      }),
    )

    // TODO: once this saga has been migrated to RTK, use invalidateTags from the api instead of here
    yield* put(
      documentCenterApi.util.invalidateTags([
        { type: Tags.MemberDocument, id: memberId },
      ]),
    )

    yield* call(notification.success, {
      message: i18n.t('documents.actions.fileSaveSuccess'),
    })

    return uploadedDocument
  } catch (e) {
    if (e.response?.status === 413) {
      // 1,048,576 binary bytes for 1 megabyte
      const sizeLimitInMb = Number(e.response.data?.detail?.limit) / 1_048_576

      yield* put(
        memberDocumentsActions.erroredSavingDocument({ memberId, error: e }),
      )
      yield* call(notification.error, {
        message: i18n.t('documents.actions.fileSaveErrorTooLarge', {
          limit: sizeLimitInMb,
        }),
      })

      return
    }

    yield* put(
      memberDocumentsActions.erroredSavingDocument({ memberId, error: e }),
    )
    yield* call(notification.error, {
      message: i18n.t('documents.actions.fileSaveError'),
    })
  }
}

export function* uploadDocument({
  payload,
}: ReturnType<typeof memberDocumentsActions.uploadDocument>) {
  yield* call(uploadMemberDocument, payload)
}

export function* uploadDocumentFromUrl({
  payload: { memberId, url, name, episodeId, createdFromNoteId },
}: ReturnType<typeof memberDocumentsActions.uploadDocumentFromUrl>) {
  try {
    const fileResponse = yield* call(fetch, url)

    if (!fileResponse.ok) {
      throw new Error(
        `Failed to download document from Document Center. memberId=${memberId}, url=${url}`,
      )
    }

    const fileBlob = yield* call([fileResponse, fileResponse.blob])
    const file = new File([fileBlob], name, { type: fileBlob.type })
    const document = yield* call(baseUploadMemberDocument, {
      memberId,
      document: file,
      episodeId,
      createdFromNoteId,
    })

    // TODO: once this saga has been migrated to RTK, use invalidateTags from the api instead of here
    yield* put(
      documentCenterApi.util.invalidateTags([
        { type: Tags.MemberDocument, id: memberId },
      ]),
    )

    yield* put(
      memberDocumentsActions.savedDocumentFromUrl({ memberId, document }),
    )

    // open the newly uploaded document in the viewer
    yield* put(documentViewerActions.viewMemberDocument({ document }))

    yield* call(notification.success, {
      message: i18n.t('documents.modalPicker.successfullyAdded'),
    })
  } catch (e) {
    yield* put(
      memberDocumentsActions.erroredSavingDocumentFromUrl({
        memberId,
        error: e,
      }),
    )
    yield* call(notification.error, {
      message: i18n.t('documents.modalPicker.errors.uploadingTemplate'),
    })
  }
}

export function* saveDocumentFromChat(
  action: ReturnType<typeof memberDocumentsActions.saveDocumentFromChat>,
) {
  const { memberId, fileInfo, episodeId } = action.payload
  let file: File

  try {
    const mmFileData = yield* call(fetchFileData, fileInfo)
    file = new File([mmFileData], fileInfo.name)
  } catch (e) {
    yield* put(
      memberDocumentsActions.erroredSavingDocument({ memberId, error: e }),
    )
    yield* call(notification.error, {
      message: i18n.t('documents.actions.fetchFileDataError'),
    })

    return
  }

  const memberDocument = yield* call(uploadMemberDocument, {
    memberId,
    document: file,
    episodeId,
    postId: fileInfo.post_id,
  })

  if (memberDocument) {
    const usher = yield* call(initUsherClient)

    const updatedPost = yield* call(usher.patchPostProps, fileInfo.post_id, {
      document_center_id: memberDocument.id,
    })
    yield* put(chatActions.updatePost(episodeId, updatedPost))
  }
}

export function* fetchFileData(fileInfo: FullFileInfo) {
  const mm = yield* call(initMMClientV4)
  const fileUrl = yield* call([mm, mm.getFileUrl], fileInfo.id, 0)
  const token = yield* select(selectAccessToken)

  const response: Response = yield* call(fetch, fileUrl, {
    headers: { Authorization: `Bearer ${token}` },
  })

  if (!response.ok) {
    throw new Error('Failed to download file from IH: ' + String(response))
  }

  const data: Blob = yield* call([response, response.blob])

  return data
}

export function* getDocumentById(memberId: number, documentId: string) {
  const client = yield* call(initMemberClient)
  const { data } = yield* call(client.getDocument, { memberId, documentId })
  return data.data
}

export function* getMemberDocumentFilters(
  action: ReturnType<typeof memberDocumentsActions.getDocumentFilters>,
) {
  const { memberId } = action.payload

  try {
    const client = yield* call(initMemberClient)
    const { data } = yield* call(client.getFiltersValues, { memberId })

    yield* put(
      memberDocumentsActions.receivedDocumentFilters({
        memberId,
        filters: data.data,
      }),
    )
  } catch (e) {
    yield* put(
      memberDocumentsActions.erroredGettingDocumentFilters({
        memberId,
        error: e,
      }),
    )
  }
}

export function* sendBlobToEpisode({
  payload,
}: ReturnType<typeof memberDocumentsActions.sendBlobToEpisode>) {
  const { episodeId, document, fileBlob } = payload
  try {
    const file = new File([fileBlob], document.name, { type: fileBlob.type })

    yield* call(uploadFileToMM, chatActions.uploadFile(episodeId, file))
  } catch (e) {
    notification.error({
      message: i18n.t('documents.actions.sendToEpisodeError'),
    })
  }
}

export function* sendToEpisode({
  payload,
}: ReturnType<typeof memberDocumentsActions.sendToEpisode>) {
  try {
    const { episodeId, document, memberId } = payload
    const client = yield* call(initMemberClient)
    const { data } = yield* call(client.getFileDownloadUrl, {
      memberId,
      documentId: document.id,
    })
    const fileUrl = data.data.url
    const fileResponse = yield* call(fetch, fileUrl)
    const fileBlob = yield* call([fileResponse, fileResponse.blob])
    yield* call(
      sendBlobToEpisode,
      memberDocumentsActions.sendBlobToEpisode({
        episodeId,
        document,
        fileBlob,
      }),
    )
    if (!fileResponse.ok) {
      throw new Error(
        `Failed to download document from Document Center. memberId=${memberId}, docId=${document.id}`,
      )
    }
  } catch (e) {
    notification.error({
      message: i18n.t('documents.actions.sendToEpisodeError'),
    })
  }
}

export function* updateDocument(
  action: ReturnType<typeof memberDocumentsActions.updateDocument>,
) {
  const { memberId, documentId, documentProperties } = action.payload

  try {
    const client = yield* call(initMemberClient)
    const { data } = yield* call(client.patchDocument, {
      memberId,
      documentId,
      patchMemberDocRequest: documentProperties,
    })

    // TODO: once this saga has been migrated to RTK, use invalidateTags from the api instead of here
    yield* put(
      documentCenterApi.util.invalidateTags([
        { type: Tags.MemberDocument, id: memberId },
      ]),
    )

    yield* put(
      memberDocumentsActions.documentUpdated({
        memberId,
        document: data.data,
      }),
    )
  } catch (error) {
    yield* put(
      memberDocumentsActions.errorUpdatingDocument({ memberId, error }),
    )
  }
}

export function* updateDocumentBytes(
  action: ReturnType<typeof memberDocumentsActions.updateDocumentBytes>,
) {
  const { memberId, documentId, document } = action.payload

  try {
    const client = yield* call(initMemberClient)
    const { data } = yield* call(client.updateDocumentBytes, {
      memberId,
      documentId,
      document,
    })

    // TODO: once this saga has been migrated to RTK, use invalidateTags from the api instead of here
    yield* put(
      documentCenterApi.util.invalidateTags([
        { type: Tags.MemberDocument, id: memberId },
      ]),
    )

    yield* put(
      memberDocumentsActions.documentBytesUpdated({
        memberId,
        document: data.data,
      }),
    )
  } catch (error) {
    yield* put(
      memberDocumentsActions.errorUpdatingDocumentBytes({ memberId, error }),
    )
  }
}

export function* uploadToIh(
  action: ReturnType<typeof memberDocumentsActions.uploadToIh>,
) {
  const { memberId, documentId, description } = action.payload

  try {
    const emerald = yield* call(initEmeraldV2Client)
    const data = yield* call(
      emerald.uploadDocCenterFile,
      memberId.toString(),
      documentId,
      description ?? null,
    )

    yield* put(
      memberDocumentsActions.savedToIh({
        memberId,
        document: data,
      }),
    )
    // TODO: once this saga has been migrated to RTK, use invalidateTags from the api instead of here
    yield* put(
      documentCenterApi.util.invalidateTags([
        { type: Tags.MemberDocument, id: memberId },
      ]),
    )

    yield* call(notification.success, {
      message: i18n.t('documents.actions.sendToIhSuccess'),
    })
  } catch (error) {
    yield* put(
      memberDocumentsActions.erroredSavingToIh({ memberId, error, documentId }),
    )
  }
}

const selectMemberId = (action: MemberAction) => action.payload.memberId
const selectDocumentId = (action: MemberDocumentAction) =>
  action.payload.documentId

export default function* memberDocumentsSagas() {
  yield* all([
    takeKeyedLatest(
      memberDocumentsActions.saveDocumentFromChat,
      (action) => action.payload.fileInfo.post_id,
      saveDocumentFromChat,
    ),
    takeKeyedLatest(
      memberDocumentsActions.uploadDocument,
      selectMemberId,
      uploadDocument,
    ),
    takeKeyedLatest(
      memberDocumentsActions.uploadDocumentFromUrl,
      selectMemberId,
      uploadDocumentFromUrl,
    ),
    takeKeyedLatest(
      memberDocumentsActions.getDocumentFilters,
      selectMemberId,
      getMemberDocumentFilters,
    ),
    takeKeyedLatest(
      memberDocumentsActions.sendToEpisode,
      (action) => action.payload.document.id,
      sendToEpisode,
    ),
    takeKeyedLatest(
      memberDocumentsActions.sendBlobToEpisode,
      (action) => action.payload.document.id,
      sendBlobToEpisode,
    ),
    takeKeyedLatest(
      memberDocumentsActions.updateDocument,
      selectMemberId,
      updateDocument,
    ),
    takeKeyedLatest(
      memberDocumentsActions.updateDocumentBytes,
      selectDocumentId,
      updateDocumentBytes,
    ),
    takeKeyedLatest(
      memberDocumentsActions.uploadToIh,
      selectDocumentId,
      uploadToIh,
    ),
  ])
}

import {
  initialize,
  basicLogger,
  type LDContext,
  type LDClient,
} from 'launchdarkly-js-client-sdk'

import config from 'app/config'

export const Flags = {
  appointmentCancelation: 'appointment-cancelation',
  appointmentReschedule: 'appointment-reschedule',
  documentTemplatePicker: 'document-template-picker',
  enableDocumentCenter: 'enable-document-center',
  enableDocumentEditing: 'enable-document-editing',
  enableEpisodeHistoryDropdown: 'enable-episode-history-dropdown',
  enableFaxInbox: 'enable-fax-inbox',
  enableFaxing: 'enable-faxing',
  enableFaxOutbox: 'enable-fax-outbox',
  enableIcbtProfileTab: 'enable-icbt-profile-tab',
  enableQuickNavDropdown: 'enable-quick-nav-dropdown',
  enableSaveChatFile: 'enable-save-chat-file',
  enableTaskSystem: 'enable-task-system',
  episodeHistoryLimit: 'episode-history-limit',
  finalizeSubnotesFromParentNote: 'finalize-subnotes-from-parent-note',
  memberMedicationList: 'member-medication-list',
  memberProfileDrawer: 'member-profile-drawer',
  taskCommentsPollingInterval: 'task-comments-polling-interval',
  useMentionsWithoutSearch: 'use-mentions-without-search',
  videoTranscription: 'video-transcription',
  taskCountPollingInterval: 'task-count-polling-interval',
  taskEditableDescriptions: 'task-editable-descriptions',
} as const

export type FlagsKeys = keyof typeof Flags
export type FlagsValues = (typeof Flags)[FlagsKeys]

export const initialLdUser: LDContext = {
  key: 'logged_out_user',
  anonymous: true,
}

interface TypedLdClient extends LDClient {
  /** Superset the LDClient type to add type checking of the key parameter. We
   * also require defaultValue. */
  variation: <T>(key: FlagsValues, defaultValue: T) => T
}

export const ldclient = initialize(config.LAUNCHDARKLY_ID, initialLdUser, {
  bootstrap: 'localStorage',
  logger: basicLogger({ level: 'info' }),
}) as TypedLdClient

import { skipToken } from '@reduxjs/toolkit/query'

import { formatUserFullName } from 'app/lib/helpers'

import { useGetPractitionerQuery } from '../api/emergency-room/practitioners'

/**
 * Custom RTK Query hook that returns a practitioner's full name.
 * @param practitionerId - The practitioner's DIA ID. If undefined, the query will be skipped.
 */
export function usePractitionerName(practitionerId: number | 'me' | undefined) {
  return useGetPractitionerQuery(
    practitionerId ? { practitionerId } : skipToken,
    {
      selectFromResult: ({ currentData, ...rest }) => ({
        ...rest,
        name:
          currentData &&
          formatUserFullName(currentData.givenName, currentData.familyName),
      }),
    },
  )
}

import { useCallback, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import type { MemberDocument } from '@dialogue/document-center'
import { Alert, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { DocumentSelectModal } from 'app/components/documents/document-select-modal'
import { MultiSelectList } from 'app/components/documents/multi-select-list'
import { TaskSection } from 'app/components/tasks/task-section'
import { useGetMemberDocumentsQuery } from 'app/redux/api/document-center/member-documents'

const MultiSelectListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`

const StyledMultiSelectList = styled(MultiSelectList)`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
`

interface MemberDocumentsMultiSelectProps {
  value?: string[]
  onChange?: (newValue: string[]) => void
  onDocumentClick?: (id: string, document: MemberDocument) => void
  memberId: number
}

export const MemberDocumentsMultiSelect = ({
  value = [],
  onChange,
  memberId,
  onDocumentClick,
}: MemberDocumentsMultiSelectProps) => {
  const { t } = useTranslation()

  const {
    data: documents = [],
    isLoading,
    isError,
  } = useGetMemberDocumentsQuery({ memberId }, { skip: !memberId })
  const attachedDocuments = documents.filter((document) =>
    value.includes(document.id),
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleRemoveDocument = useCallback(
    (removedId: string) => onChange?.(value.filter((id) => id !== removedId)),
    [onChange, value],
  )

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [setIsModalOpen])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const handleSelect = useCallback(
    (selectedDocumentId: string) => {
      const currentDocuments = value.filter((id) => id !== selectedDocumentId)
      onChange?.([...currentDocuments, selectedDocumentId])
      setIsModalOpen(false)
    },
    [onChange, value],
  )

  return (
    <MultiSelectListContainer>
      <TaskSection
        title={t('tasks.properties.labels.attachedDocuments')}
        actions={
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={handleOpenModal}
            style={{ paddingRight: 0, paddingLeft: 0 }}
            data-cy="open-templates-picker-button"
            data-dd-privacy="allow"
            data-dd-action-name="task-form:add:document"
          >
            {t('tasks.actions.addAttachment')}
          </Button>
        }
      >
        {isError && (
          <Alert
            type={'error'}
            message={t('documents.errorFetchingDocuments')}
          />
        )}
        <StyledMultiSelectList
          documents={attachedDocuments}
          loading={isLoading}
          onCancel={handleRemoveDocument}
          onClick={onDocumentClick}
        />
        <DocumentSelectModal
          open={isModalOpen}
          onSelect={handleSelect}
          onCancel={handleCloseModal}
          documents={documents}
          loading={isLoading}
        />
      </TaskSection>
    </MultiSelectListContainer>
  )
}

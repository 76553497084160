import { useCallback, useState } from 'react'

import { LinkOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import config from 'app/config'
import { useClipboard } from 'app/hooks'
import type {
  DocumentKind,
  DocumentSource,
  ViewerDocument,
} from 'app/redux/documents/viewer'
import deviceInfo from 'app/services/device-info'

import { DocumentLinkParam } from './types'

/**
 * Generate search params string needed to link to a document.
 */
const getDocumentLinkParams = (
  search?: string,
  documentId?: string,
  documentSource?: DocumentSource,
  documentKind?: DocumentKind,
) => {
  const params = new URLSearchParams(search)

  if (documentId) {
    params.set(DocumentLinkParam.ID, documentId)
  }
  if (documentSource) {
    params.set(DocumentLinkParam.SOURCE, documentSource)
  }
  if (documentKind) {
    params.set(DocumentLinkParam.KIND, documentKind)
  }

  return params.toString()
}

export const CopyUrlButton = ({ document }: { document?: ViewerDocument }) => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()

  const pathToDocument = `${pathname}?${getDocumentLinkParams(
    search,
    document?.id,
    document?.source,
    document?.kind,
  )}`

  const [currentDocumentId, setCurrentDocumentId] = useState(document?.id)
  const [openerPath, setOpenerPath] = useState(pathToDocument)

  // persist path only when document ID changes
  if (document?.id !== currentDocumentId) {
    setCurrentDocumentId(document?.id)
    setOpenerPath(pathToDocument)
  }

  // substring to remove leading `/`
  const copyLinkToDocument = useClipboard(
    `${deviceInfo.isElectron() ? config.APP_SCHEME : window.location.origin + '/#/'}${openerPath.substring(1)}`,
  )

  const handleCopyClick = useCallback(() => {
    copyLinkToDocument()
  }, [copyLinkToDocument])

  return (
    <Tooltip title={t('documents.actions.copyLink')} placement="bottomRight">
      <Button icon={<LinkOutlined />} type="link" onClick={handleCopyClick} />
    </Tooltip>
  )
}

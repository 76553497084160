import { ProviderApiFactory } from '@dialogue/notepad-client'
import { put, all, takeLatest, call } from 'typed-redux-saga/macro'

import { providerNotesActions } from 'app/redux/structured-notes/provider'

import { getConfig } from './utils'

export function* getClient() {
  return ProviderApiFactory(yield* call(getConfig))
}

export function* getOwnDrafts() {
  try {
    const notepad = yield* call(getClient)
    const { data } = yield* call(notepad.getOwnDrafts)

    yield* put(providerNotesActions.receivedOwnDrafts(data.data))
  } catch (e) {
    yield* put(providerNotesActions.fetchFailed(e))
  }
}

export default function* provider() {
  yield* all([takeLatest(providerNotesActions.getOwnDrafts, getOwnDrafts)])
}

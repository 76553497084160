// @ts-strict-ignore
import { useEffect, useState } from 'react'

import moment from 'moment'

import { getTimeStatus } from 'app/lib/time'

export const useTimeFromNow = (
  time: string | number,
  warningThreshold: number = null,
  dangerThreshold: number = null,
): string => {
  const [displayTime, setDisplayTime] = useState(() => moment(time).fromNow())

  useEffect(() => {
    const fromNowInterval = setInterval(() => {
      setDisplayTime(moment(time).fromNow())
    }, 1000)
    return () => clearInterval(fromNowInterval)
  }, [time, warningThreshold, dangerThreshold])

  return displayTime
}

export const useTimeStatusFromThreshold = (
  time: number | string,
  warningThreshold: number = null,
  dangerThreshold: number = null,
) => {
  const [status, setStatus] = useState(
    getTimeStatus(
      moment().diff(moment(time), 's'),
      warningThreshold,
      dangerThreshold,
    ),
  )

  useEffect(() => {
    const timeMoment = moment(time)
    const fromNowInterval = setInterval(() => {
      const now = moment()
      const diff = now.diff(timeMoment, 's')
      setStatus(getTimeStatus(diff, warningThreshold, dangerThreshold))
    }, 1000)
    return () => clearInterval(fromNowInterval)
  }, [time, warningThreshold, dangerThreshold])

  return status
}

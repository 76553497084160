import type {
  Document,
  PatientResponse,
} from '@dialogue/services/dist/emerald/model'
import type { ApiRequestError } from '@dialogue/services/dist/utils'
import { action } from 'typesafe-actions'

import { InputHealthTypes } from './types'

export const verifyRecord = (patientId: string) =>
  action(InputHealthTypes.VERIFY_RECORD, patientId)

export const verifyRecordSuccess = (record?: PatientResponse) =>
  action(InputHealthTypes.VERIFY_RECORD_SUCCESS, record)

export const verifyRecordError = (error: ApiRequestError) =>
  action(InputHealthTypes.VERIFY_RECORD_ERROR, error)

export const linkRecord = (patientId: string) =>
  action(InputHealthTypes.LINK_RECORD, patientId)

export const getPatientDocuments = (patientId: string | number) =>
  action(InputHealthTypes.GET_PATIENT_DOCUMENTS, patientId)

export const getPatientDocumentsSuccess = (patientDocuments: Document[]) =>
  action(InputHealthTypes.GET_PATIENT_DOCUMENTS_SUCCESS, patientDocuments)

export const getPatientDocumentsFailure = (error: ApiRequestError) =>
  action(InputHealthTypes.GET_PATIENT_DOCUMENTS_FAILURE, error)

export const startPollingPatientDocuments = (memberId: number) =>
  action(InputHealthTypes.START_POLLING_PATIENT_DOCUMENTS, memberId)

export const stopPollingPatientDocuments = () =>
  action(InputHealthTypes.STOP_POLLING_PATIENT_DOCUMENTS)

import type { Document } from '@dialogue/services/dist/emerald/model'
import type { ApiRequestError } from '@dialogue/services/dist/utils/errors'

export interface InputHealthRecord {
  link: string
  familyDoctor?: string
  address?: string
  preferredPharmacy?: string
  preferredPharmacyId?: string
  preferredPharmacyFax?: string
  phone?: string
  identifications: { [key: string]: string | number | null }
}

export interface InputHealthState {
  record?: InputHealthRecord
  fetching: boolean
  error?: ApiRequestError
  patientId?: string
  documents?: Document[]
  documentsFetching: boolean
  documentsError?: ApiRequestError
}

export enum InputHealthTypes {
  VERIFY_RECORD = '@@inputHealth/VERIFY_RECORD',
  VERIFY_RECORD_SUCCESS = '@@inputHealth/VERIFY_RECORD_SUCCESS',
  VERIFY_RECORD_ERROR = '@@inputHealth/VERIFY_RECORD_ERROR',
  LINK_RECORD = '@@inputHealth/LINK_RECORD',
  GET_PATIENT_DOCUMENTS = '@@inputHealth/GET_PATIENT_DOCUMENTS',
  GET_PATIENT_DOCUMENTS_SUCCESS = '@@inputHealth/GET_PATIENT_DOCUMENTS_SUCCESS',
  GET_PATIENT_DOCUMENTS_FAILURE = '@@inputHealth/GET_PATIENT_DOCUMENTS_FAILURE',
  START_POLLING_PATIENT_DOCUMENTS = '@@inputHealth/START_POLLING_PATIENT_DOCUMENTS',
  STOP_POLLING_PATIENT_DOCUMENTS = '@@inputHealth/STOP_POLLING_PATIENT_DOCUMENTS',
}

// @ts-strict-ignore
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

export interface AddDependentState {
  fetching: boolean
  error?: Error
}

export interface AddDependentRequest {
  memberId: string | number
  formValues: {
    firstName: string
    lastName: string
    birthdate: string
    email?: string
  }
}

export interface AddDependentFailure {
  error: Error
}

export const INITIAL_STATE: AddDependentState = {
  fetching: false,
  error: null,
}

export const { reducer, actions: addDependentActions } = createSlice({
  name: '@@addDependent',
  initialState: INITIAL_STATE,
  reducers: {
    request: (state, _: PayloadAction<AddDependentRequest>) => {
      state.fetching = true
      state.error = null
    },
    success: (state) => {
      state.fetching = false
      state.error = null
    },
    failure: (state, action: PayloadAction<AddDependentFailure>) => {
      const { error } = action.payload
      state.fetching = false
      state.error = error
    },
  },
})

export default reducer

export const selectAddDependentState = (state: ReduxState) => {
  const activeForm = state.addDependent
  return activeForm || null
}

// @ts-strict-ignore
import React, {
  useCallback,
  useMemo,
  useState,
  useImperativeHandle,
  useRef,
} from 'react'

import type { UsherTypes } from '@dialogue/services'
import type { UserProfile } from '@mattermost/types/users'
import { Mentions } from 'antd'
import type { MentionProps, MentionsRef } from 'antd/lib/mentions'
import uniqBy from 'lodash/uniqBy'
import { connect, type MapStateToPropsFactory } from 'react-redux'

import type { ReduxState } from 'app/redux'
import { selectUser, makeSelectMembers } from 'app/redux/chat/selectors'

import UserInfo from './user-info'

interface OwnProps {
  onChange?: React.ChangeEventHandler
  disableMentions?: boolean
}

interface StateProps {
  users: (UsherTypes.Physician | UserProfile)[]
  selfMattermostId: string
}

type Props = MentionProps & OwnProps & StateProps

export interface MentionsInputRef extends MentionsRef {
  getAppIdFromHandle: (handle: string) => number
}

export const MentionsInput: React.ForwardRefRenderFunction<
  MentionsInputRef,
  Props
> = ({ onChange, users, disableMentions, selfMattermostId, ...rest }, ref) => {
  // dedupe members + practitioners (so members are first)
  // and filter out coredata + self + patient
  const filteredUsers = useMemo(
    () =>
      uniqBy(users, (user) => user.username).filter(
        (user) =>
          user.props.user_type === 'physician' && user.id !== selfMattermostId,
      ),
    [users, selfMattermostId],
  )

  const innerRef = useRef(null)

  const [options, setOptions] = useState<MentionProps['options']>([])

  useImperativeHandle(
    ref,
    () => ({
      focus: () => innerRef.current?.focus(),
      blur: () => innerRef.current?.blur(),
      textarea: innerRef.current?.textarea,
      getAppIdFromHandle: (handle) =>
        Number(filteredUsers.find((u) => u.username === handle)?.props.app_id),
    }),
    [filteredUsers],
  )

  // Autocomplete expect a onChange event with a target.
  // This overloads Mention's on change to change structure
  // from string -> { target: { value: string } }
  const handleChange = useCallback(
    (value: string) => {
      onChange?.({ target: { value } } as unknown as React.ChangeEvent)
    },
    [onChange],
  )

  const handleSearch = useCallback(
    (searchTerm: string) => {
      let results = filteredUsers

      if (searchTerm) {
        // filter based on tokenized name + username
        results = filteredUsers.filter((user) =>
          [...user.nickname.split(' '), user.username].some((field) =>
            field.toLowerCase().startsWith(searchTerm.toLowerCase()),
          ),
        )
      }

      const resultOptions: MentionProps['options'] = results
        .slice(0, 10)
        .map((user) => ({
          key: user.username,
          value: user.username,
          label: (
            <UserInfo
              name={user.nickname}
              username={user.username}
              avatarSrc={user.props.picture}
            />
          ),
        }))

      setOptions(resultOptions)
    },
    [filteredUsers],
  )

  return (
    <Mentions
      {...rest}
      ref={innerRef}
      onChange={handleChange}
      prefix={disableMentions ? [] : '@'} // no trigger -> no mentions popup or search
      notFoundContent="No users found"
      onSearch={handleSearch}
      filterOption={false}
      options={options}
    />
  )
}

const mapStateToProps: MapStateToPropsFactory<
  StateProps,
  OwnProps,
  ReduxState
> = () => {
  const getMembers = makeSelectMembers()

  return (state) => {
    const episodeId = state.episodeView.episode.id

    return {
      users: getMembers(state, episodeId),
      selfMattermostId: selectUser(state)?.id,
    }
  }
}

export default connect(mapStateToProps, {}, null, { forwardRef: true })(
  React.forwardRef(MentionsInput),
)

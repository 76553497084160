import { useMemo, type ReactNode } from 'react'

import {
  type Task,
  TaskStatus,
  type TaskWithLinkedTasks,
} from '@dialogue/coredata'
import { Spin, Tree } from 'antd'
import styled from 'styled-components'

import { TaskRow } from './task-row'

const StyledTaskTree = styled(Tree)`
  .ant-tree-switcher {
    margin: 8px 0;
  }
`

const EmptyTasksList = styled.div`
  text-align: center;
  opacity: 0.2;
  padding: 16px;
`

const getTreeData = (tasks: TaskWithLinkedTasks[], showCompleted: boolean) => {
  return tasks.map((task: TaskWithLinkedTasks) => {
    const filteredSubTasks = task.subtasks
      ? showCompleted
        ? task.subtasks
        : task.subtasks.filter((subtask) => subtask.status === TaskStatus.TO_DO)
      : []

    return {
      title: <TaskRow task={task} />,
      key: task.id,
      children: filteredSubTasks.map((subtask: Task) => {
        return {
          title: <TaskRow task={subtask} />,
          key: subtask.id,
        }
      }),
    }
  })
}

interface TasksListProps {
  tasks: TaskWithLinkedTasks[]
  loading?: boolean
  emptyText: ReactNode
  showCompleted: boolean
}

export const TasksList = ({
  tasks,
  loading = false,
  emptyText,
  showCompleted,
}: TasksListProps) => {
  const treeData = useMemo(
    () => getTreeData(tasks, showCompleted),
    [tasks, showCompleted],
  )

  const noTasksDue = <EmptyTasksList>{emptyText}</EmptyTasksList>

  if (loading) return <Spin />

  if (tasks.length) {
    return (
      <StyledTaskTree
        defaultExpandAll
        blockNode
        treeData={treeData}
        selectable={false}
      />
    )
  }

  return noTasksDue
}

export default TasksList

import { Space } from 'antd'

import { DocumentKind, type ViewerDocument } from 'app/redux/documents/viewer'

import { CopyUrlButton } from './copy-link-button'
import { ModifyDocumentActions } from './modify-document'
import { SendDocumentActions } from './send-document'
import type { DOCUMENT_ACTION } from './types'

interface Props {
  document: ViewerDocument
  /**
   * Gets called when the parent should close the panel (if possible)
   */
  onClose?: () => void
  onSendFaxClick: () => void | null
  onActionClick?: (action: DOCUMENT_ACTION) => void
  disableSendFax: boolean
}

export const DocumentActions = ({
  document,
  onClose,
  onSendFaxClick,
  onActionClick,
  disableSendFax,
}: Props) => {
  const showDocumentActions =
    document?.kind !== DocumentKind.OUTGOING_FAX_DOCUMENT &&
    document?.kind !== DocumentKind.ANTICIPATED_OUTGOING_FAX

  return (
    showDocumentActions && (
      <Space>
        <ModifyDocumentActions
          document={document}
          onActionClick={onActionClick}
        />
        <SendDocumentActions
          document={document}
          onClose={onClose}
          onSendFaxClick={onSendFaxClick}
          disableSendFax={disableSendFax}
        />
        <CopyUrlButton document={document} />
      </Space>
    )
  )
}

import { useCallback, useContext, useEffect } from 'react'

import type { NewTask, UpdateTask } from '@dialogue/coredata'
import { Form, Tag, Typography } from 'antd'
import type { FormProviderProps } from 'antd/lib/form/context'
import { useTranslation } from 'react-i18next'

import { StyledDrawer } from 'app/components/ant-design'
import { DrawerManagementContext } from 'app/components/drawer-manager/context'
import {
  TASK_DRAWER_ID,
  TaskDrawerContext,
} from 'app/containers/tasks/task-drawer-context'
import {
  useCreateTaskMutation,
  useUpdateTaskMutation,
} from 'app/redux/api/emergency-room/tasks'

import { TASK_ATTACHMENTS_FORM_NAME } from './task-attachments-form'
import {
  NewTaskDrawerContents,
  TaskDrawerContents,
} from './task-drawer-contents'
import { getFormName } from './task-form'

const NewTag = () => {
  const { t } = useTranslation()
  return (
    <Tag style={{ marginLeft: 8 }} data-dd-privacy="allow">
      {t('tasks.newTag')}
    </Tag>
  )
}

export const TaskDrawer = () => {
  const { t } = useTranslation()

  const { activeDrawer, closeDrawer } = useContext(DrawerManagementContext)
  const { task, initialData, isLoading, setDrawerTask } =
    useContext(TaskDrawerContext)

  const [createTask] = useCreateTaskMutation()
  const [updateTask] = useUpdateTaskMutation()

  const isNewTask = !task?.id && !isLoading

  const handleCreateTask = useCallback(
    (values: NewTask) => {
      return createTask(values)
        .unwrap()
        .then(({ data: createdTask }) => {
          const { id, parent_id } = createdTask
          if (parent_id) {
            setDrawerTask(parent_id)
            return
          }
          setDrawerTask(id)
        })
    },
    [createTask, setDrawerTask],
  )

  const handleUpdateTask = useCallback(
    (taskId: string, values: UpdateTask) => {
      return updateTask({ taskId, ...values }).unwrap()
    },
    [updateTask],
  )

  const handleFormsConnection = useCallback<
    NonNullable<FormProviderProps['onFormChange']>
  >(
    (name, { forms }) => {
      const attachmentsForm = forms[TASK_ATTACHMENTS_FORM_NAME]

      if (name === TASK_ATTACHMENTS_FORM_NAME) {
        const taskId = attachmentsForm?.getFieldValue('id')
        const parentId = attachmentsForm?.getFieldValue('parent_id')
        const attachments = attachmentsForm?.getFieldValue('document_ids') || []

        // If its a new task, set attachments in the task form,
        // If its existing task, immediately update the task with the new attachments
        if (isNewTask) {
          const taskForm = forms[getFormName(taskId)]
          taskForm.setFieldValue('document_ids', attachments)
        } else {
          const updatedTaskId = parentId || taskId
          handleUpdateTask(updatedTaskId, { document_ids: attachments })
        }
      }
    },
    [handleUpdateTask, isNewTask],
  )

  const newTaskDrawerTitle = (
    <Typography.Text data-dd-privacy="allow">
      {!!initialData?.parent_id
        ? t('tasks.actions.createSubtask')
        : t('tasks.actions.create')}

      <NewTag />
    </Typography.Text>
  )

  useEffect(() => {
    return () => closeDrawer()
  }, [closeDrawer])

  return (
    <StyledDrawer
      open={activeDrawer === TASK_DRAWER_ID}
      onClose={closeDrawer}
      width={'70%'}
      mask={false}
      destroyOnClose
      bodyStyle={{ height: '100%', overflow: 'visible' }}
      closable={false}
      data-cy="task-details-drawer"
      title={isNewTask ? newTaskDrawerTitle : ''}
    >
      <Form.Provider onFormChange={handleFormsConnection}>
        {isNewTask && initialData && (
          <NewTaskDrawerContents
            initialData={initialData}
            onCreate={handleCreateTask}
          />
        )}
        {task && <TaskDrawerContents task={task} onUpdate={handleUpdateTask} />}
      </Form.Provider>
    </StyledDrawer>
  )
}

export default TaskDrawer

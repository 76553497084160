import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'app/hooks'
import {
  formatPhoneNumber,
  formatPractitionerName,
  formatUserFullName,
} from 'app/lib/helpers'
import { useGetEpisodeQuery } from 'app/redux/api/emergency-room/episodes'
import type { ViewerOutgoingFaxDocument } from 'app/redux/documents/viewer'

import { formatDocumentDate } from '../../helpers'

import {
  DetailLine,
  HeaderLine,
  PanelContent,
  type OutgoingFaxMetadata,
} from './common'
import { generateKeyFromLabel } from './utils'

interface Props {
  document: ViewerOutgoingFaxDocument
}

export const OutgoingFaxDocumentPanel = ({ document }: Props) => {
  const { t } = useTranslation()
  const providers = useAppSelector((state) => state.practitioners?.profiles)
  const providerProfile = providers?.[document.created_by_user_id]
  const providerName = formatPractitionerName(
    providerProfile,
    document.created_by_user_id.toString(),
  )

  const { data: episodeData } = useGetEpisodeQuery(
    { episodeId: document.member_document.episode_id || '' },
    { skip: !document.member_document.episode_id },
  )

  const faxMetadata: OutgoingFaxMetadata = {
    fax_package_properties: [
      {
        label: t('faxes.table.sentTo'),
        value: formatPhoneNumber(document.receiver_number),
      },
      {
        label: t('faxes.table.sentOn'),
        value: formatDocumentDate({
          t,
          date: document.sent_at,
          includeTime: true,
        }),
      },
      {
        label: t('faxes.table.sentBy'),
        value: providerName || '',
      },
      {
        label: t('faxes.table.document'),
        value: document.member_document.name,
      },
      {
        label: t('documents.faxDetails.confirmationNumber'),
        value: document.srfax_id,
      },
    ],
    document_properties: [
      {
        label: t('documents.faxDetails.member'),
        value: formatUserFullName(
          episodeData?.patient_first_name,
          episodeData?.patient_last_name,
          episodeData?.patient_preferred_name,
        ),
      },
      {
        label: t('documents.faxDetails.episode'),
        value: `${episodeData?.title || t('episodes.missingTitle')}`,
      },
    ],
  }

  return (
    <PanelContent size={4} direction="vertical">
      <Typography.Text strong>
        {t('documents.faxDetails.faxInformation')}
      </Typography.Text>

      <HeaderLine title={t('documents.faxDetails.faxPackageProperties')} />
      {faxMetadata.fax_package_properties.map(
        ({ label, value }) =>
          value && (
            <DetailLine
              key={generateKeyFromLabel(label)}
              label={label}
              value={<Typography.Text>{value}</Typography.Text>}
            />
          ),
      )}

      {episodeData && (
        <>
          <HeaderLine title={t('documents.faxDetails.documentProperties')} />
          {faxMetadata.document_properties.map(({ label, value }) => (
            <DetailLine
              key={generateKeyFromLabel(label)}
              label={label}
              value={<Typography.Text>{value}</Typography.Text>}
            />
          ))}
        </>
      )}
    </PanelContent>
  )
}

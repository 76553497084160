import { combineReducers } from 'redux'

import patients from './patient'
import provider from './provider'
import templates from './templates'

export default combineReducers({
  patients,
  provider,
  templates,
})

import moment, { type Moment } from 'moment-timezone'

export const secondsToDisplayTime = (x: number): string => {
  const seconds = x % 60
  const minutes = (x - seconds) / 60
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

export const getTimeStatus = (
  timeDelta: number,
  warningThreshold: number | null = null,
  dangerThreshold: number | null = null,
): 'valid' | 'warning' | 'danger' => {
  if (dangerThreshold && timeDelta > dangerThreshold) {
    return 'danger'
  }

  if (warningThreshold && timeDelta > warningThreshold) {
    return 'warning'
  }

  return 'valid'
}

export const getTimezonedTime = (
  timezoneId: string,
  momentObj: Moment = moment(),
) => {
  if (timezoneId) {
    return momentObj.tz(timezoneId).format('(H:mm z)')
  }
  return ''
}

const ADMIN_AREA_TO_TIMEZONE_MAPPING: Record<string, any> = {
  AB: 'Canada/Mountain',
  BC: 'Canada/Pacific',
  SK: 'Canada/Central',
  MB: 'Canada/Central',
  ON: 'Canada/Eastern',
  QC: 'Canada/Eastern',
  NB: 'Canada/Atlantic',
  NS: 'Canada/Atlantic',
  PE: 'Canada/Atlantic',
  NL: 'Canada/Newfoundland',
  NU: 'Canada/Eastern',
  NT: 'Canada/Mountain',
  YT: 'Canada/Pacific',
}

export const getTimezoneIdFromAdminArea = (adminArea: string) => {
  if (adminArea in ADMIN_AREA_TO_TIMEZONE_MAPPING) {
    return ADMIN_AREA_TO_TIMEZONE_MAPPING[adminArea]
  } else {
    return ADMIN_AREA_TO_TIMEZONE_MAPPING.QC
  }
}

import { all, call, put, takeEvery } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import type { ReduxState } from 'app/redux'
import * as episodeMetaActions from 'app/redux/episode-meta/actions'
import { EpisodeMetaTypes } from 'app/redux/episode-meta/types'
import { initEmergencyRoomClient } from 'app/sagas/utils'

export const episodeMetaState = (state: ReduxState) => state.episodeMeta

export function* requestProperties() {
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)

    const propertiesResponse = yield* call(emergencyRoom.getEpisodeProperties)
    yield* put(episodeMetaActions.requestPropertiesSuccess(propertiesResponse))
  } catch (error) {
    yield* put(episodeMetaActions.requestPropertiesFailure(error))
  }
}

export function* updateEpisodeProperties({
  payload,
}: ActionType<typeof episodeMetaActions.updateEpisodeProperties>) {
  const { episodeId, properties, onChange } = payload
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)

    yield* call(emergencyRoom.updateEpisodeProperties, episodeId, properties)

    yield* put(episodeMetaActions.updateEpisodePropertiesSuccess())
    if (onChange) {
      yield* call(onChange)
    }
  } catch (error) {
    yield* put(episodeMetaActions.updateEpisodePropertiesFailure(error))
  }
}

export function* updateEpisodeTitle({
  payload,
}: ActionType<typeof episodeMetaActions.updateEpisodeTitle>) {
  const { episodeId, title } = payload
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    yield* call(emergencyRoom.updateEpisodeTitle, episodeId, title)
    yield* put(episodeMetaActions.updateEpisodePropertiesSuccess())
  } catch (error) {
    yield* put(episodeMetaActions.updateEpisodePropertiesFailure(error))
  }
}

export default function* episodeMetaSagas() {
  yield* all([
    takeEvery(EpisodeMetaTypes.REQUEST_PROPERTIES, requestProperties),
    takeEvery(
      EpisodeMetaTypes.UPDATE_EPISODE_PROPERTIES,
      updateEpisodeProperties,
    ),
    takeEvery(EpisodeMetaTypes.UPDATE_EPISODE_TITLE, updateEpisodeTitle),
  ])
}

import type {
  ItemPractitioner,
  CollectionPractitioner,
  CollectionPractitionerStatusOption,
  FullPractitionerUpdate,
  PractitionerApiGetPractitionerRequest,
  PractitionerApiGetPractitionersRequest,
  Practitioner,
} from '@dialogue/coredata'

import { emergencyRoomApi, Tags } from './api'

export const practitionersApi = emergencyRoomApi.injectEndpoints({
  endpoints: (build) => ({
    getPractitioner: build.query({
      query: ({ practitionerId }: PractitionerApiGetPractitionerRequest) => ({
        url: `/v1/practitioners/${practitionerId}`,
      }),
      transformResponse: (response: ItemPractitioner) => response.data,
      providesTags: (_result, _error, { practitionerId }) => [
        { type: Tags.Practitioner, id: practitionerId },
      ],
    }),
    getPractitioners: build.query({
      query: ({ statusSince }: PractitionerApiGetPractitionersRequest) => ({
        url: '/v1/practitioners',
        params: {
          status_since: statusSince,
        },
      }),
      transformResponse: (response: CollectionPractitioner) => response.data,
      providesTags: (_result, _error, { statusSince }) => [
        { type: Tags.Practitioner, id: statusSince },
      ],
    }),
    getPractitionerStatusOptions: build.query({
      query: (_: void) => ({
        url: '/v1/app-data/practitioner-status',
      }),
      transformResponse: (response: CollectionPractitionerStatusOption) =>
        response.data,
    }),
    getMePractitioner: build.query({
      query: (_: void) => ({
        url: '/v1/practitioners/me',
      }),
      transformResponse: (response: ItemPractitioner) => response.data,
      providesTags: (result, _error, _arg) => [
        { type: Tags.Practitioner, id: result?.id },
      ],
    }),
    updateMePractitioner: build.mutation<Practitioner, FullPractitionerUpdate>({
      query: (body) => ({
        url: '/v1/practitioners/me',
        method: 'PUT',
        body,
      }),
      transformResponse: (response: ItemPractitioner) => response.data,
      invalidatesTags: (result, _error, _arg) => [
        { type: Tags.Practitioner, id: 'LIST' },
        { type: Tags.Practitioner, id: result?.id },
      ],
    }),
  }),
})

export const {
  useGetPractitionerQuery,
  useGetPractitionersQuery,
  useGetPractitionerStatusOptionsQuery,
  useGetMePractitionerQuery,
  useUpdateMePractitionerMutation,
} = practitionersApi

import type { TeamQueue } from '@dialogue/coredata'
import { action } from 'typesafe-actions'

import { EpisodeQueuesTypes, type TeamEpisodeGroup } from './types'

export const enqueueEpisode = (
  episodeId: string,
  selectedQueue: string,
  selectedAction?: string,
) =>
  action(EpisodeQueuesTypes.ENQUEUE_EPISODE, {
    episodeId,
    selectedQueue,
    selectedAction,
  })

export const enqueueEpisodeSuccess = (
  episodeId: string,
  selectedQueue?: string,
  queuedAt?: string,
  selectedAction?: string,
) =>
  action(EpisodeQueuesTypes.ENQUEUE_EPISODE_SUCCESS, {
    episodeId,
    selectedQueue,
    queuedAt,
    selectedAction,
  })

export const enqueueEpisodeError = (episodeId: string, error: Error) =>
  action(EpisodeQueuesTypes.ENQUEUE_EPISODE_ERROR, { episodeId, error })

export const startPollingTeamEpisodes = (teamId: string) =>
  action(EpisodeQueuesTypes.START_POLLING_TEAM_EPISODES, { teamId })

export const stopPollingTeamEpisodes = (teamId: string) =>
  action(EpisodeQueuesTypes.STOP_POLLING_TEAM_EPISODES, { teamId })

export const setTeamEpisodeGroups = (
  teamId: string,
  episodeGroups: TeamEpisodeGroup[],
) =>
  action(EpisodeQueuesTypes.SET_TEAM_EPISODE_GROUPS, {
    teamId,
    episodeGroups,
  })

export const startPollingTeams = () =>
  action(EpisodeQueuesTypes.START_POLLING_TEAMS)

export const stopPollingTeams = () =>
  action(EpisodeQueuesTypes.STOP_POLLING_TEAMS)

export const setTeams = (teams: TeamQueue[]) =>
  action(EpisodeQueuesTypes.SET_TEAMS, {
    teams,
  })

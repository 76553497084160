const routes = {
  channelPost: (
    patientId: string | number,
    episodeId: string,
    postId: string,
  ) => `/chat/${patientId}/${episodeId}?postId=${postId}`,
  channel: (patientId: number | string, episodeId = '') =>
    `/chat/${patientId}/${episodeId}`,
  patientNote: (
    patientId: number | string,
    episodeId: string,
    noteId: number,
  ) =>
    `${routes.channel(patientId, episodeId)}?noteId=${noteId}&episodeTab=notes`,
  memberDocumentInChannel: (
    memberId: number,
    episodeId: string,
    documentId: string,
  ) =>
    `/chat/${memberId}/${episodeId}?tab=Documents&documentId=${documentId}&documentSource=Document+Center`,
  memberDocumentInProfile: (memberId: number, documentId: string) =>
    `/member/${memberId}?tab=Documents&documentId=${documentId}&documentSource=Document+Center`,
  userProfile: (userId: string) => `/users/${userId}`,
  consult: (patientId: string | number, episodeId: string) =>
    `/consult/${patientId}/${episodeId}`,
  mentions: () => '/mentions',
  dashboard: () => '/dashboard',
  schedule: () => '/schedule',
  practitionerSchedule: (practitionerId: string | number) =>
    `/schedule?tab=single-view&practitioner-id=${practitionerId}`,
  pending: () => '/pending',
  resolved: () => '/resolved',
  followups: () => '/reminders',
  tasks: () => '/tasks',
  faxesInbox: () => '/faxes-inbox',
  faxesOutbox: () => '/faxes-outbox',
  assignedToMe: () => '/assigned-to-me',
  ownDraftNotes: () => '/own-draft-notes',
  login: () => '/login',
  memberProfile: (patientId: number | string) => `/member/${patientId}`,
}

export default routes

import type {
  CollectionIncomingFaxDocument,
  FaxesApiGetFileDownloadUrlRequest,
  FaxesApiGetReceivedFaxesRequest,
  FaxesApiGetSentFaxesRequest,
  FaxesApiLinkReceivedFaxToMemberRequest,
  FaxesApiSplitIncomingFaxDocumentRequest,
  FaxesApiUpdateReceivedFaxRequest,
  IncomingFaxDocument,
  ItemDownloadUrlResponse,
  ItemIncomingFaxDocument,
  OutgoingFaxDocument,
  PageIncomingFaxDocument,
  PageOutgoingFaxDocument,
  SendFaxRequest,
} from '@dialogue/document-center'
import { decamelizeKeys } from 'humps'

import { Tags, documentCenterApi } from './api'

export const faxesApi = documentCenterApi.injectEndpoints({
  endpoints: (builder) => ({
    getIncomingFaxes: builder.query<
      PageIncomingFaxDocument,
      FaxesApiGetReceivedFaxesRequest
    >({
      query: (params) => ({
        url: '/faxes/incoming',
        params,
      }),
      providesTags: (result, _error) =>
        result
          ? [
              { type: Tags.IncomingFaxDocument, id: 'LIST' },
              ...result.data.map(({ id }) => ({
                type: Tags.IncomingFaxDocument,
                id,
              })),
            ]
          : [{ type: Tags.IncomingFaxDocument, id: 'LIST' }],
    }),

    updateIncomingFax: builder.mutation<
      ItemIncomingFaxDocument,
      FaxesApiUpdateReceivedFaxRequest
    >({
      query: ({ documentId, patchIncomingFaxDocRequest }) => ({
        url: `/faxes/incoming/${documentId}`,
        method: 'PATCH',
        body: decamelizeKeys(patchIncomingFaxDocRequest),
      }),
      invalidatesTags: (_result, _error, { documentId }) => [
        { type: Tags.IncomingFaxDocument, id: 'LIST' },
        { type: Tags.IncomingFaxDocument, id: documentId },
      ],
    }),

    assignMemberToFaxDocument: builder.mutation<
      IncomingFaxDocument,
      FaxesApiLinkReceivedFaxToMemberRequest
    >({
      query: ({ documentId, bodyFaxesLinkReceivedFaxToMember }) => ({
        url: `/faxes/incoming/${documentId}/link`,
        method: 'POST',
        body: bodyFaxesLinkReceivedFaxToMember,
      }),
      invalidatesTags: () => [{ type: Tags.IncomingFaxDocument, id: 'LIST' }],
    }),

    splitFax: builder.mutation({
      query: ({
        documentId,
        files,
      }: FaxesApiSplitIncomingFaxDocumentRequest) => {
        const body = new FormData()
        files.forEach((file) => body.append('files', file))

        return {
          url: `/faxes/incoming/${documentId}/split`,
          method: 'POST',
          body,
        }
      },
      transformResponse: (response: CollectionIncomingFaxDocument) =>
        response.data,
      // Invalidate the list to remove the split fax and load the newly created sections
      invalidatesTags: () => [{ type: Tags.IncomingFaxDocument, id: 'LIST' }],
    }),

    getIncomingFaxDownloadUrl: builder.query({
      query: ({ documentId }: FaxesApiGetFileDownloadUrlRequest) => ({
        url: `/faxes/incoming/${documentId}/download-url`,
      }),
      transformResponse: (response: ItemDownloadUrlResponse) =>
        response.data.url,
    }),

    sendFax: builder.mutation<OutgoingFaxDocument, SendFaxRequest>({
      query: (body) => ({
        url: '/faxes/outgoing',
        method: 'POST',
        body,
      }),
    }),

    getOutgoingFaxes: builder.query<
      PageOutgoingFaxDocument,
      FaxesApiGetSentFaxesRequest
    >({
      query: (params) => ({
        url: '/faxes/outgoing',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: Tags.OutgoingFaxDocument,
                id: 'LIST',
              },
              ...result.data.map(({ id }) => ({
                type: Tags.OutgoingFaxDocument,
                id,
              })),
            ]
          : [],
    }),

    getOutgoingFaxDownloadUrl: builder.query({
      query: ({ documentId }: FaxesApiGetFileDownloadUrlRequest) => ({
        url: `/faxes/outgoing/${documentId}/download-url`,
      }),
      transformResponse: (response: ItemDownloadUrlResponse) =>
        response.data.url,
    }),
  }),
})

export const {
  useGetIncomingFaxesQuery,
  useUpdateIncomingFaxMutation,
  useAssignMemberToFaxDocumentMutation,
  useSplitFaxMutation,
  useGetIncomingFaxDownloadUrlQuery,
  useLazyGetIncomingFaxDownloadUrlQuery,
  useSendFaxMutation,
  useGetOutgoingFaxesQuery,
  useGetOutgoingFaxDownloadUrlQuery,
  useLazyGetOutgoingFaxDownloadUrlQuery,
} = faxesApi

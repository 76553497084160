import type { Note } from '@dialogue/notepad-client'
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'
import { selectUserId } from 'app/redux/authentification/selectors'

interface State {
  fetching: boolean
  error: Error | null
  notes: Note[] | null
}

const INITIAL_STATE: State = {
  fetching: false,
  error: null,
  notes: null,
}

export const { reducer, actions: providerNotesActions } = createSlice({
  name: '@@structuredNotes/provider',
  initialState: INITIAL_STATE,
  reducers: {
    getOwnDrafts(state) {
      state.fetching = true
      state.error = null
    },

    receivedOwnDrafts(state, { payload }: PayloadAction<Note[]>) {
      state.fetching = false
      state.notes = payload
    },

    fetchFailed(state, { payload }: PayloadAction<Error>) {
      state.fetching = false
      state.error = payload
    },
  },
})

export default reducer

export const selectOwnNotes = (state: ReduxState) =>
  state.structuredNotes.provider.notes

export const selectOwnDrafts = createSelector(
  selectOwnNotes,
  selectUserId,
  (notes, providerId) => {
    if (notes === null) {
      return null
    }
    // flatten list of all subnotes ids
    const subnoteIds = notes.flatMap((note) => note.sub_note_ids || [])

    return notes.filter(
      (note) =>
        note.status === 'draft' &&
        note.created_by === providerId &&
        !subnoteIds.includes(note.id),
    )
  },
)

export const selectProviderNotesLoading = (state: ReduxState) =>
  state.structuredNotes.provider.fetching

// @ts-strict-ignore
import { useCallback, useState } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import type {
  MemberDocument,
  SendFaxRequest,
  TemplateResponse,
} from '@dialogue/document-center'
import { notification } from 'antd'
import { parsePhoneNumber } from 'libphonenumber-js'
import { useTranslation } from 'react-i18next'

import { StyledDrawer } from 'app/components/ant-design'
import { DocumentActions } from 'app/containers/documents/document-actions'
import type { DOCUMENT_ACTION } from 'app/containers/documents/document-actions/types'
import { safeJoin } from 'app/containers/documents/helpers'
import {
  FaxDetailsForm,
  type SendFaxFormValues,
} from 'app/containers/documents/send-fax'
import { useAppSelector } from 'app/hooks'
import { formatUserFullName } from 'app/lib/helpers'
import { useSendFaxMutation } from 'app/redux/api/document-center/faxes'
import { useLazyGetMemberDocumentDownloadUrlQuery } from 'app/redux/api/document-center/member-documents'
import { selectUserProfile } from 'app/redux/authentification/selectors'
import {
  DocumentKind,
  DocumentSource,
  type ViewerAnticipatedOutgoingFaxDocument,
} from 'app/redux/documents/viewer'
import { selectEpisodeViewEpisode } from 'app/redux/episode-view/selectors'

import { DocumentDetailsContents } from './details-contents'
import type { OutgoingFaxMetadata } from './info-panel/common'
import { useDocumentDetails } from './use-document-details'
import { useApryse, type OutgoingFaxAutofillFields } from './utils'
import { MODAL_TYPE, useViewerInstance } from './viewer-context'

export const DocumentDrawer = (props: {}) => {
  const { document, closeDocument } = useDocumentDetails()
  const [previousDocumentId, setPreviousDocumentId] = useState<string | null>(
    document?.id || null,
  )

  const { t } = useTranslation()

  const apryse = useApryse()
  const [showFaxDetailsForm, setShowFaxDetailsForm] = useState(false)
  const [documentAction, setDocumentAction] = useState<DOCUMENT_ACTION | null>(
    null,
  )
  const [outgoingFaxMetadata, setOutgoingFaxMetadata] =
    useState<OutgoingFaxMetadata | null>(null)

  const { isViewerEditable, setModal } = useViewerInstance()
  const [tempViewerDocument, setTempViewerDocument] =
    useState<ViewerAnticipatedOutgoingFaxDocument | null>(null)
  const [sendFax] = useSendFaxMutation()
  const userProfile = useAppSelector(selectUserProfile)
  const providerFullName = safeJoin(
    [userProfile?.givenName, userProfile?.familyName],
    ' ',
  )

  if (document?.id !== previousDocumentId) {
    setShowFaxDetailsForm(false)
    setTempViewerDocument(null)
    setDocumentAction(null)
    setPreviousDocumentId(document?.id)
  }

  const handleSendFaxClick = useCallback(() => {
    setShowFaxDetailsForm(true)
  }, [])

  const handleActionClick = useCallback((action: DOCUMENT_ACTION) => {
    setDocumentAction(action)
  }, [])

  const handleConfirmSendFaxClick = useCallback(async () => {
    try {
      const blob_b64 = (await apryse.blobToBase64(
        tempViewerDocument.blob,
      )) as string

      const body: SendFaxRequest = {
        receiver_number: tempViewerDocument.receiver_number,
        document_id: tempViewerDocument.member_document_id,
        fax_document_base64: blob_b64.replace(/data:.*?;base64,/, ''),
        template_name: tempViewerDocument.fax_cover_page.name,
      }

      await sendFax(body)
        .unwrap()
        .then(() => {
          setShowFaxDetailsForm(false)
          setTempViewerDocument(null)
          closeDocument()
        })
    } catch (error) {
      notification.error({
        message: t('documents.errorSendingFax'),
        props: { 'data-testid': 'error-sending-fax' },
      })
      console.error('Error sending fax:', error)
    }
  }, [apryse, tempViewerDocument, sendFax, closeDocument, t])

  const handleFaxDetailsClose = useCallback(() => {
    setShowFaxDetailsForm(false)
  }, [])

  const handleCloseDocument = useCallback(() => {
    if (isViewerEditable) {
      setModal({ type: MODAL_TYPE.CONFIRM_SAVE, onCancel: closeDocument })
      return
    }

    setShowFaxDetailsForm(false)
    setDocumentAction(null)
    closeDocument()
  }, [closeDocument, isViewerEditable, setModal])

  const handleCloseTempViewerDocument = useCallback(() => {
    setTempViewerDocument(null)
    setShowFaxDetailsForm(true)
  }, [])

  const episodeData = useAppSelector(selectEpisodeViewEpisode)

  const [fetchDocumentDownloadUrl] = useLazyGetMemberDocumentDownloadUrlQuery()

  const handleSubmitFaxDetailsForm = useCallback(
    async (formInputs: SendFaxFormValues) => {
      try {
        const {
          fax_number,
          fax_recipient,
          fax_cover_page,
          fax_cover_page_custom_text,
        } = formInputs

        const faxNumber = parsePhoneNumber(fax_number, 'CA')
        const faxCoverPage: TemplateResponse = JSON.parse(fax_cover_page)
        const documentUrl = await fetchDocumentDownloadUrl({
          documentId: document?.id,
          memberId: document?.member_id,
        }).unwrap()

        const autofillFields: OutgoingFaxAutofillFields = {
          patient_dia_id: document.member_id.toString(),
          outgoing_fax_recipient_name: fax_recipient,
          outgoing_fax_recipient_fax_number: faxNumber.formatNational(),
          todays_date: new Date().toLocaleDateString('en-CA'),
          provider_full_name: providerFullName,
        }

        // using custom template
        if (fax_cover_page_custom_text) {
          autofillFields.outgoing_fax_template_text = fax_cover_page_custom_text
        }

        const blob = await apryse.prepareFaxPackage(
          faxCoverPage?.url,
          documentUrl,
          autofillFields,
        )

        const receiverNumberFormatted =
          faxNumber.countryCallingCode + faxNumber.nationalNumber

        setTempViewerDocument({
          blob,
          fax_cover_page: faxCoverPage,
          receiver_number: receiverNumberFormatted,
          source: DocumentSource.OUTGOING_FAX_DOCUMENT,
          kind: DocumentKind.ANTICIPATED_OUTGOING_FAX,
          member_id: document?.member_id,
          member_document_id: document?.id,
          episode_id: (document as MemberDocument)?.episode_id,
          // N/A
          id: undefined,
          name: undefined,
          url: undefined,
        })
        setOutgoingFaxMetadata({
          fax_package_properties: [
            {
              label: t('documents.faxDetails.sendTo'),
              value: faxNumber.formatNational(),
            },
            { label: t('documents.faxDetails.type'), value: faxCoverPage.name },
            {
              label: t('documents.faxDetails.pages'),
              value: autofillFields.outgoing_fax_page_count,
            },
          ],
          document_properties: [
            {
              label: t('documents.faxDetails.member'),
              value: formatUserFullName(
                episodeData?.patient_first_name,
                episodeData?.patient_last_name,
                episodeData?.patient_preferred_name,
              ),
            },
            {
              label: t('documents.faxDetails.episode'),
              value: `${episodeData?.title || t('episodes.missingTitle')}`,
            },
          ],
        })
        setShowFaxDetailsForm(false)
      } catch (error) {
        notification.error({
          message: t('documents.errorPreparingFaxPackage'),
          props: { 'data-testid': 'error-preparing-fax-package' },
        })
        console.error('Error preparing a fax package:', error)
      }
    },
    [
      fetchDocumentDownloadUrl,
      document,
      providerFullName,
      apryse,
      t,
      episodeData?.patient_first_name,
      episodeData?.patient_last_name,
      episodeData?.patient_preferred_name,
      episodeData?.title,
    ],
  )

  const isOpen = !!document || !!tempViewerDocument
  const disableSendFax =
    showFaxDetailsForm ||
    document?.source === DocumentSource.INCOMING_FAX_DOCUMENT

  if (!apryse.loaded) return null // TODO: add spinner

  return (
    <StyledDrawer
      {...props}
      placement="right"
      maskClosable={false}
      mask={false}
      onClose={!!tempViewerDocument ? undefined : handleCloseDocument}
      open={isOpen}
      closeIcon={!!tempViewerDocument ? false : <CloseOutlined />}
      width={'75%'}
      data-testid="document-drawer"
      extra={
        <DocumentActions
          document={tempViewerDocument || document}
          onClose={closeDocument}
          onSendFaxClick={handleSendFaxClick}
          onActionClick={handleActionClick}
          disableSendFax={disableSendFax}
        />
      }
    >
      {!showFaxDetailsForm && (
        <DocumentDetailsContents
          key={document?.id}
          document={tempViewerDocument || document}
          onClose={closeDocument}
          onConfirmSendFax={handleConfirmSendFaxClick}
          onOutgoingFaxDocumentCancel={handleCloseTempViewerDocument}
          outgoingFaxMetadata={outgoingFaxMetadata}
          documentAction={documentAction}
          setDocumentAction={setDocumentAction}
          css={`
            height: 100%;
          `}
        />
      )}
      {showFaxDetailsForm && (
        <FaxDetailsForm
          member_document_id={document?.id}
          member_id={document?.member_id}
          handleCancel={handleFaxDetailsClose}
          handleSubmit={handleSubmitFaxDetailsForm}
        />
      )}
    </StyledDrawer>
  )
}

import { memo, type ReactNode } from 'react'

import { Space, Typography } from 'antd'
import styled from 'styled-components'

import { colors } from 'app/theme'

const Dot = styled.div`
  height: 5px;
  width: 5px;
  background-color: ${colors.textDisabled};
  border-radius: 50%;
`

const SubOptionInfo = styled(Typography.Text)`
  font-size: 12px;
`

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const EllipsizedSpace = styled(Space)`
  display: flex;
  .ant-space-item:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const OptionSubHeader = memo(
  ({
    leftText,
    rightText,
    split,
  }: {
    leftText?: ReactNode
    rightText?: ReactNode
    split?: ReactNode
  }) => (
    <SubOptionInfo type="secondary" ellipsis>
      <EllipsizedSpace split={split || <Dot />}>
        {leftText}
        {rightText}
      </EllipsizedSpace>
    </SubOptionInfo>
  ),
)

export const OptionContainer = memo(
  ({
    header,
    subHeader,
  }: {
    header?: string | number | JSX.Element
    subHeader?: string | number | JSX.Element
  }) => {
    return (
      <OptionWrapper>
        <Typography.Text ellipsis>{header}</Typography.Text>
        {subHeader}
      </OptionWrapper>
    )
  },
)

import { useCallback, useEffect } from 'react'

import type { MemberDocument } from '@dialogue/document-center'
import { Form, type FormProps } from 'antd'

import { useOpenMemberDocument } from 'app/containers/documents/document-details/useOpenMemberDocument'
import { MemberDocumentsMultiSelect } from 'app/containers/documents/member-document-multi-select'

export const TASK_ATTACHMENTS_FORM_NAME = 'task-attachments-form'

export const TaskAttachmentsForm = <FormType extends {}>({
  disabled,
  initialValues = {},
  ...rest
}: FormProps<FormType>) => {
  const [form] = Form.useForm()
  const openMemberDocument = useOpenMemberDocument()
  const memberIdFieldValue = Form.useWatch('member_id', form)

  useEffect(() => {
    form.resetFields()
  }, [initialValues?.id, form])

  const handleOpenDocument = useCallback(
    (id: string, document: MemberDocument) => {
      openMemberDocument(document)
    },
    [openMemberDocument],
  )

  return (
    <Form
      {...rest}
      form={form}
      name={TASK_ATTACHMENTS_FORM_NAME}
      initialValues={initialValues}
      disabled={disabled}
    >
      <Form.Item name="member_id" hidden />
      <Form.Item name="id" hidden />
      <Form.Item name="parent_id" hidden />
      <Form.Item name="document_ids" noStyle>
        <MemberDocumentsMultiSelect
          memberId={memberIdFieldValue}
          onDocumentClick={handleOpenDocument}
        />
      </Form.Item>
    </Form>
  )
}
